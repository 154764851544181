import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import Project from './Project'

import './index.scss'

const Projects = ({ data, setCursor }) => {
  return (
    <section data-scroll-section className="projects" data-theme="fog">
      <ul>
        {data.project.map((project) => (
          <li className="projects__project" key={project.title}>
            <Project media={project.media} title={project.title} setCursor={setCursor}/>
            <div className="projects__text">
              <div className="projects__text-inner">
                <h5 className="projects__subtitle">{project.subtitle}</h5>
                <div className="projects__description">
                  <div className="projects__description-inner">
                    <ReactMarkdown>{project.description}</ReactMarkdown>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  )
}

Projects.propTypes = {
  data: PropTypes.shape({
    project: PropTypes.arrayOf(PropTypes.shape({
      media: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        video: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        mobile_image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        mobile_video: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      })),
      title: PropTypes.string,
      subtitle: PropTypes.string,
      description: PropTypes.string,
    })),
  }),
  setCursor: PropTypes.func.isRequired
}

export default Projects
