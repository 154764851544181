export default class Logo {
  constructor(elem) {
    this.elem = elem
    this.lines = [...this.elem.querySelectorAll('[data-header-line]')]
    this.letters = [...this.elem.querySelectorAll('[data-header-letter]')]

    this.targetState = null
    this.isAnimating = false
    this.isShrunk = false

    window.addEventListener('pr-is-scrolled', this.stateChangeHandler)
  }

  stateChangeHandler = (event) => {
    if (!this.isShrunk && event.detail.isScrolled) {
      if (this.isAnimating) {
        this.targetState = 'shrunk'
      } else {
        this.shrink()
      }
    } else if (this.isShrunk && !event.detail.isScrolled) {
      if (this.isAnimating) {
        this.targetState = 'full'
      } else {
        this.expand()
      }
    }
  }

  getRects = (items) => {
    return items.map((item) => item.getBoundingClientRect())
  }

  setStyles = (items, rects) => {
    items.forEach((item, index) => {
      item.style.left = `${rects[index].left}px`
      item.style.top = `${rects[index].top}px`
      item.style.width = `${rects[index].width}px`
    })
  }

  resetStyles = (items) => {
    items.forEach((item, index) => {
      item.style.left = ''
      item.style.top = ''
      item.style.width = ''
    })
  }

  shrink = () => {
    if (this.isShrunk) {
      return
    }
    this.isAnimating = true
    this.isShrunk = true
    this.targetState = null
    const originalLineRects = this.getRects(this.lines)
    const originalLetterRects = this.getRects(this.letters)
    this.elem.classList.add('is-condensed')
    requestAnimationFrame(() => {
      const targetLineRects = this.getRects(this.lines)
      const targetLetterRects = this.getRects(this.letters)
      this.elem.classList.remove('is-condensed')
      this.elem.classList.add('is-fixed')
      this.setStyles(this.lines, originalLineRects)
      this.setStyles(this.letters, originalLetterRects)

      requestAnimationFrame(() => {
        this.elem.classList.add('is-line')
        this.elem.classList.add('is-shrinking')

        setTimeout(() => {
          this.setStyles(this.lines, targetLineRects)
          this.setStyles(this.letters, targetLetterRects)

          setTimeout(() => {
            this.resetStyles(this.lines)
            this.resetStyles(this.letters)

            this.elem.classList.remove('is-fixed')
            this.elem.classList.remove('is-shrinking')
            this.elem.classList.add('is-condensed')
            this.isAnimating = false
            if (this.targetState === 'full') {
              this.expand()
            }
          }, 800)
        }, 300)
      })
    })
  }

  expand = () => {
    if (!this.isShrunk) {
      return
    }
    this.isAnimating = true
    this.isShrunk = false
    this.targetState = null
    const originalLineRects = this.getRects(this.lines)
    const originalLetterRects = this.getRects(this.letters)
    this.elem.classList.remove('is-condensed')

    requestAnimationFrame(() => {
      const targetLineRects = this.getRects(this.lines)
      const targetLetterRects = this.getRects(this.letters)

      this.elem.classList.add('is-fixed')
      this.setStyles(this.lines, originalLineRects)
      this.setStyles(this.letters, originalLetterRects)

      requestAnimationFrame(() => {
        this.elem.classList.add('is-expanding')
        this.setStyles(this.lines, targetLineRects)
        this.setStyles(this.letters, targetLetterRects)

        setTimeout(() => {
          this.elem.classList.remove('is-line')

          setTimeout(() => {
            this.resetStyles(this.lines)
            this.resetStyles(this.letters)

            this.elem.classList.remove('is-fixed')
            this.elem.classList.remove('is-expanding')
            this.isAnimating = false
            if (this.targetState === 'shrunk') {
              this.shrink()
            }
          }, 420)
        }, 420)
      })
    })
  }

  destroy = () => {
    window.removeEventListener('pr-is-scrolled', this.stateChangeHandler)
  }
}
