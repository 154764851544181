import React, { useState } from 'react'
import Layout from '~global/Layout'
import Header from '~components/Header'
import Hero from '~components/Hero'
import Projects from '~components/Projects'
import Experience from '~components/Experience'
import Carousel from '~components/Carousel'
import Outro from '~components/Outro'

import Cursor from '~components/Cursor'

import heroData from '../content/homepage/hero.yaml'
import projectsData from '../content/homepage/projects.yaml'
import experienceData from '../content/homepage/experience.yaml'
import carouselData from '../content/homepage/carousel.yaml'
import outroData from '../content/homepage/outro.yaml'

const IndexPage = () => {
  const [isCursorVisible, setCursorVisible] = useState(false)
  const [cursorType, setCursorType] = useState('arrow-right')

  const setCursor = (visible, type) => {
    setCursorVisible(visible)
    if (type) {
      setCursorType(type)
    }
  }

  return (
    <Layout>
      <Header />
      <Hero data={heroData} />
      <Projects data={projectsData} setCursor={setCursor} />
      <Experience data={experienceData} />
      <Carousel data={carouselData} setCursor={setCursor} />
      <Outro data={outroData} />

      <Cursor isVisible={isCursorVisible} type={cursorType} />
    </Layout>
  )
}

export default IndexPage

