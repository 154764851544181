import React from 'react'
import sanitizeHtml from 'sanitize-html';
import PropTypes from 'prop-types'

import './index.scss'

const sanitizeTitle = (dirty, options) => ({
  __html: sanitizeHtml(dirty, {allowedTags: ['br']})
})

const handleLinebreak = (text) => text.replace(/\n/g, '<br>')

const Experience = ({ data }) => {
  return (
    <section data-scroll-section className="experience" data-theme="daylight">
      <div className="experience__content">
        <div className="experience__content-inner">
          <h2 data-scroll data-scroll-direction="horizontal" data-scroll-speed="1.7" data-scroll-offset="300" className="experience__title">{data.title}</h2>
          <h3 className="experience__column-title">{data.clients.title}</h3>
          <ul className="experience__clients">
            {data.clients.client.map((client) => (
              <li className="experience__client" key={client}>{client}</li>
            ))}
          </ul>
          <div className="experience__columns">
            {data.columns.map((column) => (
              <div data-scroll className="experience__column" key={column.title}>
                <h3 className="experience__column-title">{column.title}</h3>
                <ul>
                  {column.paragraphs.map((paragraph) => (
                    <li className="experience__column-paragraph"  key={paragraph}
                      dangerouslySetInnerHTML={sanitizeTitle(handleLinebreak(paragraph))} />
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

Experience.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    clients: PropTypes.shape({
      title: PropTypes.string,
      experience: PropTypes.arrayOf(PropTypes.string),
    }),
    columns: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      paragraphs: PropTypes.arrayOf(PropTypes.string),
    })),
  }),
}

export default Experience
