import React, { useEffect, useRef } from 'react'
import Logo from './logo'

import './index.scss'

const Header = () => {
  const headerRef = useRef()

  useEffect(() => {
    const logo = new Logo(headerRef.current)

    return () => logo.destroy()
  }, [])

  return (
    <h1 className="header" aria-label="Phil Ruppanner" ref={headerRef}>
      <span data-header-letter className="header__letter header__letter__p">P</span>
      <span data-header-line className="header__line header__line--top">
        <span className="header__line-line"></span>
        <span className="header__line-text">hil</span>
      </span>
      <span className="header__space">&nbsp;</span>
      <span data-header-line className="header__line header__line--bottom">
        <span className="header__line-line"></span>
        <span className="header__line-text">Ruppanne</span>
      </span>
      <span data-header-letter className="header__letter header__letter__r">r</span>
    </h1>
  )
}

export default Header
