import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '~hooks/useMediaQuery'
import Media from '~components/Media'
import classNames from 'classnames'

const animate = (setScale, requestRef) => {
  setScale(prevState => prevState - 1)
  requestRef.current = requestAnimationFrame(() => animate(setScale, requestRef))
}

const ProjectLink = ({ hash, state, image, video, noTransition, zIndex }) => {
  const isMobile = useMediaQuery('screen and (max-width: 768px)')
  const imageRef = useRef(null)
  const requestRef = useRef(null)
  const [scale, setScale] = useState(isMobile ? 0 : 30)

  const hasAnimation = scale > 0

  useEffect(() => {
    requestRef.current = requestAnimationFrame(() => animate(setScale, requestRef))
    return () => cancelAnimationFrame(requestRef.current)
  }, [hasAnimation, setScale, requestRef])

  return (
    <div className={classNames('projects__transition-media', state)} style={{zIndex: zIndex}}>
      {!noTransition && !isMobile && scale > 0 && imageRef.current && (
        <>
          <svg className="projects__filter" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">
            <filter id={`displacementFilter-${hash}`}>
              <feTurbulence
                type="turbulence" baseFrequency="0.05"
                x="0" y="0" width={imageRef.current.clientWidth} height={imageRef.current.clientHeight}
                numOctaves="2" result="turbulence"
              />
              <feDisplacementMap in2="turbulence" in="SourceGraphic" scale={scale}
                xChannelSelector="R" yChannelSelector="G" data-reveal-map/>
            </filter>
          </svg>
          <div className="projects__image">
            <Media classes="projects__image__image" image={image} video={video}/>
          </div>
        </>
      )}
      <div className="projects__image" ref={imageRef}
        style={{filter: `url(#displacementFilter-${hash})`}}>
        <Media classes="projects__image__image" image={image} video={video}/>
      </div>
    </div>
  )
}

ProjectLink.propTypes = {
  media: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    video: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    mobile_image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    mobile_video: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  })),
  title: PropTypes.string,
  setCursor: PropTypes.func.isRequired
}

export default ProjectLink
