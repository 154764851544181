import React from 'react'
import PropTypes from 'prop-types'
import ItemLink from './ItemLink'

import './index.scss'

const Carousel = ({ data, setCursor }) => {
  return (
    <section className="carousel" data-theme="night" data-scroll-section>
      <ul className="carousel__row">
        {data.posts.map((post) => (
          <li className="carousel__post" key={post.title}>
            <ItemLink url={post.url} image={post.image} title={post.title}
              subtitle={post.subtitle} setCursor={setCursor} />
          </li>
        ))}
      </ul>
    </section>
  )
}

Carousel.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.arrayOf(PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      url: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
    })),
  }),
  setCursor: PropTypes.func.isRequired,
}

export default Carousel
