import React from 'react'
import PropTypes from 'prop-types'
import Media from '~components/Media'

const ItemLink = ({ url, image, title, subtitle, setCursor }) => {
  return (
    <a className="carousel__link" href={url} target="_blank" rel="noreferrer noopener"
      onMouseEnter={() => setCursor(true, 'external-link')} onMouseLeave={() => setCursor(false)}>
      <div className="carousel__image-wrap">
        <Media classes="carousel__image" image={image} />
      </div>
      <h3 className="carousel__title">{title}</h3>
      <p className="carousel__subtitle">{subtitle}</p>
    </a>
  )
}

ItemLink.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  url: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  setCursor: PropTypes.func.isRequired,
}

export default ItemLink
