import React, { useState } from 'react'
import sanitizeHtml from 'sanitize-html'
import PropTypes from 'prop-types'
import useMediaQuery from '~hooks/useMediaQuery'
import { Transition, TransitionGroup } from 'react-transition-group'
import quickHash from '~utils/quickHash'

import TransitionMedia from './TransitionMedia'

const sanitizeTitle = (dirty, options) => ({
  __html: sanitizeHtml(dirty, {allowedTags: ['br']})
});

const ProjectLink = ({ media, title, setCursor }) => {
  const isMobile = useMediaQuery('screen and (max-width: 768px)')
  const [imageChanged, setImageChanged] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const [currentZIndex, setCurrentZIndex] = useState(0)

  const currentItem = media[currentImage]
  const image = isMobile && currentItem.mobile_image ? currentItem.mobile_image : currentItem.image
  const video = isMobile && currentItem.mobile_video ? currentItem.mobile_video : currentItem.video
  const hash = quickHash(image + video)

  const gotoPrev = () => {
    setImageChanged(true)
    setCurrentImage(currentImage === 0 ? media.length - 1 : currentImage - 1)
    setCurrentZIndex(index => index + 1)
  }

  const gotoNext = () => {
    setImageChanged(true)
    setCurrentImage((currentImage + 1) % media.length)
    setCurrentZIndex(index => index + 1)
  }

  return (
    <div className="projects__block">
      <div className="projects__images">
        <div className="projects__images-inner">
          <TransitionGroup>
            <Transition key={`${hash}${currentZIndex}`}
              timeout={{
                appear: 0,
                enter: 0,
                exit: 1000,
              }}>
              {state => (
                <TransitionMedia hash={hash} state={state} zIndex={currentZIndex}
                  image={image} video={video} noTransition={!imageChanged}/>
              )}
            </Transition>
          </TransitionGroup>
        </div>
        <button className="projects__arrow projects__arrow--prev"
          onMouseEnter={() => setCursor(true, 'arrow-left')}
          onMouseLeave={() => setCursor(false)}
          onClick={gotoPrev}
          aria-label="Previous image"></button>
        <button className="projects__arrow projects__arrow--next"
          onMouseEnter={() => setCursor(true, 'arrow-right')}
          onMouseLeave={() => setCursor(false)}
          onClick={gotoNext}
          aria-label="Next image"></button>
      </div>
      <h3 className="projects__title" data-scroll data-scroll-delay="0.06" data-scroll-speed="1.5" data-scroll-call="dynamicColor" data-scroll-repeat
        dangerouslySetInnerHTML={sanitizeTitle(title)} />
      {media.map((item) => (
        <>
          <link rel="prefetch" href={item.image} />
          <link rel="prefetch" href={item.mobile_image} />
        </>
      ))}
    </div>
  )
}

ProjectLink.propTypes = {
  media: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    video: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    mobile_image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    mobile_video: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  })),
  title: PropTypes.string,
  setCursor: PropTypes.func.isRequired
}

export default ProjectLink
