import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'


import './index.scss'

const Hero = ({ data }) => {
  return (
    <section data-scroll-section className="hero">
      <div className="hero__body" data-scroll>
        <ReactMarkdown>{data.text}</ReactMarkdown>
      </div>
      <div className="hero__init hero__init--p" data-scroll data-scroll-speed="12" data-scroll-position="top" data-scroll-repeat>P</div>
      <div className="hero__init hero__init--r" data-scroll data-scroll-speed="2" data-scroll-position="top" data-scroll-repeat>R</div>
    </section>
  )
}

Hero.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string,
    name: PropTypes.string,
  }),
}

export default Hero
