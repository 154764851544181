import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames';
import PropTypes from 'prop-types'

import useMediaQuery from '~hooks/useMediaQuery'

import './index.scss'

const SPEED_THREASHOLD = 0.1
const SPEED_REDUCE = 0.2
const SPEED_BASE = 1200

const Cursor = ({ isVisible, type }) => {
  const [speed, setSpeed] = useState(0)
  const [position, setPosition] = useState({x: 0, y: 0})
  const isMobile = useMediaQuery('screen and (max-width: 768px)')
  const requestRef = useRef()
  const timeoutRef = useRef()
  const positionRef = useRef(typeof window !== 'undefined' ? window.innerWidth / 2 : 0)
  const targetSpeedRef = useRef(0)

  useEffect(() => {
    if (!isMobile) {
      const updateSpeed = () => {
        cancelAnimationFrame(requestRef.current)
        setSpeed(prevSpeed => {
          const delta = targetSpeedRef.current - prevSpeed
          if (delta > -SPEED_THREASHOLD && delta < SPEED_THREASHOLD) {
            return targetSpeedRef.current
          }

          return delta * SPEED_REDUCE + prevSpeed
        })
        requestRef.current = requestAnimationFrame(updateSpeed)
      }

      const handleSpeed = (event) => {
        const {clientX, clientY} = event;
        cancelAnimationFrame(requestRef.current)
        clearTimeout(timeoutRef.current)
        targetSpeedRef.current = (clientX - positionRef.current) / window.innerWidth * SPEED_BASE
        positionRef.current = clientX
        requestRef.current = requestAnimationFrame(updateSpeed)
        timeoutRef.current = setTimeout(() => targetSpeedRef.current = 0, 100)
        setPosition({x: clientX, y: clientY})
      }

      window.addEventListener('mousemove', handleSpeed)
      return () => {
        window.removeEventListener('mousemove', handleSpeed)
      }
    }
  }, [isMobile, setSpeed, setPosition])

  const cssVars = {
    '--cursor-speed': speed,
    '--cursor-x': `${position.x}px`,
    '--cursor-y': `${position.y}px`,
  }

  if (isMobile) {
    return null
  } else {
    return (
      <div className={classNames('cursor', {
        'is-visible': isVisible,
        'is-arrow-left': type === 'arrow-left',
        'is-arrow-right': type === 'arrow-right',
        'is-external-link': type === 'external-link',
      })} style={cssVars}>
        <div className="cursor__inner">
          <span className="cursor__arrow-left">←</span>
          <span className="cursor__arrow-right">→</span>
          <span className="cursor__external">Open</span>
        </div>
      </div>
    )
  }
}

Cursor.propTypes = {
  isVisible: PropTypes.bool,
  type: PropTypes.string,
}

export default Cursor
