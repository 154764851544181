import React from 'react'
import PropTypes from 'prop-types'

const Media = ({ alt = '', image, video, classes }) => {
  if (!!image) {
    const imageUrl = !!image.url ? image.url : image
    if (!!video) {
      const videoUrl = !!video.url ? video.url : video
      return <video className={classes} src={videoUrl} poster={imageUrl} alt={alt} autoPlay muted playsInline loop />
    } else {
      return <img className={classes} src={imageUrl} alt={alt} />
    }
  }

  return null
}

Media.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  video: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  classes: PropTypes.string,
}

export default Media
