import React from 'react'
import PropTypes from 'prop-types'

import './index.scss'

const Outro = ({ data }) => {
  return (
    <footer className="outro" data-scroll-section data-theme="mette">
      <h2 data-scroll data-scroll-speed="5" data-scroll-repeat className="outro__title">{data.title}</h2>
      <ul className="outro__socials">
        {data.socials.map((social) => (
          <a className="outro__social" href={social.url}
            target="_blank" rel="noreferrer noopener" key={social.title}>{social.title}</a>
        ))}
      </ul>
    </footer>
  )
}

Outro.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    socials: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    })),
  }),
}

export default Outro
